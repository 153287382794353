export default {
  Auth: {
    identityPoolId: 'us-east-1:661c225c-4d90-470f-82ef-da01192bc34c',
    region: 'us-east-1',
    userPoolId: 'us-east-1_shGPVDNeH',
    userPoolWebClientId: '3r8av5f7sqvuamfiaplqpf69nj',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  API: {
    aws_appsync_graphqlEndpoint: 'https://nh22cuehqnd63bt7bgklbju5um.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
  Storage: {
    AWSS3: {
      bucket: 'lighting-dev-assets-storage',
      region: 'us-east-1',
    },
  },
  oauth: {
    domain: 'auth2.dev.gosourcery.com',
  },
};
